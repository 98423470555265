import React from "react";
// import './Product.css';
import "antd/dist/antd.css";
import { PageHeader, Button } from "antd";
import { ProductList } from "../config/Configs.js";


let product;
const handleClick = (mat) =>{
  // window.configurator.setConfiguration({Fabric: mat})
  console.log(mat)
}


class Product extends React.Component {

  render() {
    const productId = this.props.match.params.productId;
    product = ProductList[productId];
    return (
      <div>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => window.history.back()}
          title={product.name}
        />
  
        {product.type == "3D" ?        
        <div className="tk-container">
          <div
            id="player"
            style={{
              height: 600,
              width: "96%",
              marginLeft: "auto",
              marginRight: "auto",
              background: "#F0F2F5"
            }}
          ></div>
          <div className="btn-container">
            {product.materials.map(mat => 
              
              <Button key={mat} onClick={() => window.configurator.setConfiguration({Fabric: mat})}>{mat}</Button>
               )}
          </div>
        </div> : <p>This is a 2D product</p>}

      </div>
    );
  }

  componentDidMount() {

    console.log("Component mounted");
    console.log(product)
    window
    .threekitPlayer({
      authToken: "7aa67234-74fd-4fa5-b095-702f2ef415fa",
      el: document.getElementById("player"),
      assetId: "d2550cea-e845-47ee-acdd-75568ac230dc",
      showConfigurator: false,
      showAR: false
    })
    .then(async api => {
       window.player = api;
       window.configurator = await api.getConfigurator();
      });  

  }
}

export default Product;
