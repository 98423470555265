const ProductList = {
            "1":{
                "type": "3D",
                "imageURL":"https://i.imgur.com/LuzVUpc.png",
                "threekit" : "d2550cea-e845-47ee-acdd-75568ac230dc",
                "name" :"Suit Configurator",
                "brand": "3D Suit",
                "materials": ["Striped", "Grey", "Blue Windowpane"]
            }
        };
export {ProductList};